import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    respDataProduct: null,
    respDataSelectionProduct: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    GET_PRODUCT(state, payload) {
      state.respDataProduct = payload
    },
    GET_SELECTION(state, payload) {
      state.respDataSelectionProduct = payload
    },
    ADD(state, payload) {
      state.respData.data.unshift(payload)
      // eslint-disable-next-line operator-assignment
      state.respData.max = state.respData.max + 1
    },
    ADD_PRODUCT(state, payload) {
      state.respDataProduct.data.unshift(payload)
      // eslint-disable-next-line operator-assignment
      state.respDataProduct.max = state.respDataProduct.max + 1
    },
    UPDATE(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload._id)
      Object.assign(state.respData.data[productIndex], payload)
    },
    REMOVE(state, id) {
      if (state.respData != null) {
        const ItemIndex = state.respData.data.findIndex(p => p._id === id)
        state.respData.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max - 1
      }
    },
    REMOVE_PRODUCT(state, id) {
      if (state.respDataProduct != null) {
        const ItemIndex = state.respDataProduct.data.findIndex(p => p._id === id)
        state.respDataProduct.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respDataProduct.max = state.respDataProduct.max - 1
      }
    },
  },
  actions: {
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/eCommerce/promotion/get?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getSelectionProduct({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/eCommerce/promotion/getSelectionProduct?id=${item.id}&pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET_SELECTION', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getListProduct({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/eCommerce/promotion/getListProduct?id=${item.id}&pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET_PRODUCT', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    add({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/eCommerce/promotion/add', item)
          .then(response => {
            commit('ADD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    addListProduct({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/eCommerce/promotion/addListProduct', item)
          .then(response => {
            commit('ADD_PRODUCT', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/promotion/update', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    delete({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/promotion/delete', item)
          .then(response => {
            commit('REMOVE', item.id)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    deleteListProduct({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/promotion/deleteListProduct', item)
          .then(response => {
            commit('REMOVE_PRODUCT', item.productId)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
